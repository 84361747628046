/*!

=========================================================
* Future-Cubes - v1.0.0
=========================================================
* Coded by Ecrubit
*/
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AnchorScroll } from "helper";
import { isMobile } from "../../helper/index";
import { useWeb3React } from '@web3-react/core';
import WalletDropdown from "components/ConnectWallet/WalletDropdown";
import connectors from "../../helper/walletConnector"
import activateInjectedProvider from "../../helper/walletHelp"
import ConnectWallet from "components/ConnectWallet"
import { FaLinkedinIn, FaInstagram, FaTwitter, FaFacebookF } from "react-icons/fa"
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Row,
  Col
} from "reactstrap";
import { FiMenu } from 'react-icons/fi';
import { FaTimes } from 'react-icons/fa'

export default function IndexNavbar() {

  const [collapseOpen, setCollapseOpen] = React.useState(false)
  const [collapseOut, setCollapseOut] = React.useState("")
  const { account, active, activate } = useWeb3React()
  const [isOpen, setIsOpen] = useState(false)

  const toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(!collapseOpen);
  };
  const onCollapseExiting = () => {
    setCollapseOut("collapsing-out");
  };
  const onCollapseExited = () => {
    setCollapseOut("");
  };

  function Navigation() {
    if ((/Mobi|Android/i.test(navigator.userAgent))) {
      window.open('https://play.google.com/store/apps/details?id=com.Future-Cubestc.Future-Cubes_fans_app&hl=en_US&gl=US', '_blank');
    }
    else if (/Mobi|iPad|iPhone|iPod/i.test(navigator.userAgent)) {
      window.open('https://apps.apple.com/us/app/Future-Cubes-invest-in-talent/id1604593854', '_blank');
    }
    else {
      window.open('https://www.Future-Cubestc.com/get-started', '_blank');
    }
  }

  useEffect(() => {
    const provider = localStorage.getItem("provider");
    if (provider) {
      if (provider === 'injected') {
        if (!isMobile() && !window.ethereum) {
          window.open('https://metamask.io/download/', '_blank');
        } else {
          activateInjectedProvider('MetaMask');
          activate(connectors.injected);
        }
      } else activate(connectors[provider]);
    }

  }, []);

  return (
    <div className="mx-2 mx-md-5 position-rel">
      <Navbar expand="lg" className="bg-nav py-2 mt-5">
        <Row className="w-100 align-items-center">
          <Col md="12" lg="5" className="px-0">
            <div className="d-flex justify-content-between d-lg-none">
              <NavbarBrand to="/" tag={Link} id="navbar-brand" onClick={() => AnchorScroll()}>
                {/* <img src={require('../../assets/img/logo.png').default} alt='logo' width={50} className='img-fluid' /> */}
                <div className="rounded-lg p-5 d-flex flex-column align-items-center justify-content-center logo-box">
                  <h2 className="text-white mb-0 ff-logo">Cubes</h2>
                  <h2 className="text-white mb-0 ff-logo">NFTs</h2>
                </div>
              </NavbarBrand>
              <button
                aria-expanded={collapseOpen}
                className="navbar-toggler navbar-toggler mt-0"
                onClick={toggleCollapse}
              >
                <FiMenu className="text-white" size="24" />
              </button>
            </div>
            <div className="d-none d-lg-block">
              <Nav navbar className="nav-menu p-1">
                <NavItem className="px-2">
                  <NavLink
                    data-placement="bottom"
                    rel="noopener noreferrer"
                  >
                    <a href="/" rel='noreferrer' className="text-white ff-clash-semibold">Home</a>
                  </NavLink>
                </NavItem>
                <NavItem className="px-2">
                  <NavLink
                    data-placement="bottom"
                    rel="noopener noreferrer"
                  >
                    <a href="/#about" rel='noreferrer' onClick={() => AnchorScroll()} className="text-white ff-clash-semibold">About Us</a>
                  </NavLink>
                </NavItem>
                {/* <NavItem className="px-2">
                  <NavLink
                    data-placement="bottom"
                    rel="noopener noreferrer"
                  >
                    <a href="/#faq" rel='noreferrer' onClick={() => AnchorScroll()} className="text-white ff-clash-semibold">FAQ</a>
                  </NavLink>
                </NavItem> */}
              </Nav>
              <button
                aria-expanded={collapseOpen}
                className="navbar-toggler navbar-toggler"
                onClick={toggleCollapse}
              >
                <FiMenu className="text-white" size="24" />
              </button>
            </div>
          </Col>
          <Col lg="2" className="d-flex justify-content-end pr-0">
            <div className="navbar-logo text-center d-none d-lg-block" >
              <NavbarBrand to="/" tag={Link} id="navbar-brand" onClick={() => AnchorScroll()}>
                <div className="rounded-lg p-5 d-flex flex-column align-items-center justify-content-center logo-box">
                  <h2 className="text-white mb-0 ff-logo">Cubes</h2>
                  <h2 className="text-white mb-0 ff-logo">NFTs</h2>
                </div>
              </NavbarBrand>
            </div>
            <Collapse
              className={"ml-4 justify-content-end " + collapseOut}
              navbar
              isOpen={collapseOpen}
              onExiting={onCollapseExiting}
              onExited={onCollapseExited}
            >
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-close text-right" xs="12">
                    <button
                      aria-expanded={collapseOpen}
                      className="navbar-toggler"
                      onClick={toggleCollapse}
                    >
                      <FaTimes className="pf-text-secondary" size="24" />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav navbar className="nav-menu p-1 d-lg-none d-block">
                <NavItem className="px-2">
                  <NavLink
                    data-placement="bottom"
                    rel="noopener noreferrer"
                  >
                    <a href="/" rel='noreferrer' onClick={AnchorScroll} className="text-white ff-clash-semibold">Home</a>
                  </NavLink>
                </NavItem>
                <NavItem className="px-2">
                  <NavLink
                    data-placement="bottom"
                    rel="noopener noreferrer"
                  >
                    <a href="/#about" rel='noreferrer' onClick={AnchorScroll} className="text-white ff-clash-semibold">About Us</a>
                  </NavLink>
                </NavItem>
                <NavItem className="px-2">
                  <NavLink
                    data-placement="bottom"
                    rel="noopener noreferrer"
                  >
                    <a href="/#faq" rel='noreferrer' className="text-white ff-clash-semibold">FAQ</a>
                  </NavLink>
                </NavItem>
              </Nav>
              <div className="d-lg-none">
                <div className="social-div text-right d-flex flex-row">
                  <a href="https://www.instagram.com/aiarteye/" target={'_blank'} rel='noreferrer'><FaInstagram size="35" color="white" className="my-3" /></a>
                </div>
                <div className="py-1 ml-lg-2 mt-2 mt-lg-0">
                  {(!active) &&
                    <button className="button-50 p-3 ff-clash-bold mx-0" onClick={() => setIsOpen(true)}>CONNECT WALLET</button>
                  }
                  {active && account &&
                    <WalletDropdown />
                  }
                </div>
              </div>
            </Collapse>
          </Col>
          <Col lg="5" className="d-none d-lg-flex justify-content-end">
            <div className="social-div text-right d-flex flex-row justify-content-center">
              <a href="https://www.instagram.com/aiarteye/" target={'_blank'} rel='noreferrer'><FaInstagram size="35" color="white" className="my-3" /></a>
            </div>
            <div className=" py-1 ml-lg-3 mt-2 mt-lg-0">
              {(!active) &&
                <button className="button-50 p-3 ff-clash-bold mx-0" onClick={() => setIsOpen(true)}>CONNECT WALLET</button>
              }
              {active && account &&
                <WalletDropdown />
              }
            </div>
          </Col>
        </Row>
      </Navbar>
      <ConnectWallet setIsOpen={setIsOpen} isOpen={isOpen} />
    </div>
  );
}
