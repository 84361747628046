import React, { useEffect } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap'
/* wallet imports */
import { useWeb3React } from '@web3-react/core'
import connectors from "../../helper/walletConnector"
import activateInjectedProvider from "../../helper/walletHelp"
import { AiOutlineCloseCircle } from "react-icons/ai"

import IconOne from '../../assets/img/Landing/icon-1.png';
import IconThree from '../../assets/img/Landing/icon-3.png';


function ConnectWallet({ isOpen, setIsOpen }) {
    
    const { account, activate, active } = useWeb3React();

    const connectWallet = (walletName) => {

        localStorage.setItem('provider', walletName)
        if (walletName === 'injected') {
            activateInjectedProvider('injected')
            activate(connectors.injected)
        } else if (walletName === 'walletConnect') {
            activate(connectors.walletConnect);
        } else if (walletName === 'coinbaseWallet') {
            activate(connectors.coinbaseWallet)
        }
    }

    useEffect(() => {
        if (active && account) {
            setIsOpen(false)
        }
    }, [active, account])

    return (
        <>
            <Modal isOpen={isOpen} toggle={() => setIsOpen(false)} size="md" >
                <ModalBody className='mt-3 mb-3 wallets-modal'>
                    <div className="closeButton float-right pb-5" onClick={() => setIsOpen(false)}><AiOutlineCloseCircle size={30} className='pointer' /></div>
                    <div className="row mt-3">
                        <div className="col-lg-6 col-md-6">
                            <div
                                className="appie-single-service text-center mt-30 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="200ms"
                            >
                                <div className="icon">
                                    <img src={IconOne} alt="" className='wallet-img' />
                                </div>
                                <h4 className="ff-clash-bold text-dark">Metamask</h4>
                                <div className='pt-3'>
                                    <button className='button-50 py-2 px-4 ff-clash-bold text-white mx-0' type='submit' onClick={() => connectWallet('injected')}>SELECT</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div
                                className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
                                data-wow-duration="2000ms"
                                data-wow-delay="600ms"
                            >
                                <div className="icon">
                                    <img src={IconThree} alt="" className='wallet-img p-2' />
                                </div>
                                <div>
                                    <h4 className="ff-clash-bold text-dark pt-3">Wallet Connect</h4>
                                </div>

                                <div className='pt-0'>
                                    <button className='button-50 py-2 px-4 ff-clash-bold text-white mx-0 mt-3' type='submit' onClick={() => connectWallet('walletConnect')}>SELECT</button>
                                </div>
                            </div>
                        </div>

                    </div>

                </ModalBody>
            </Modal>
        </>
    );
}

export default ConnectWallet;
