import React from 'react'
import { Container, Row, Col, Form, FormGroup, Input, Label, Button } from 'reactstrap'

const Follow_Us = () => {
    return (
        <div className='bg-social'>
            <Container>
                <Row className='py-5'>
                    <Col xs="12" sm="2">
                    </Col>
                    <Col xs="12" sm="8">
                        <h4 className='ff-mona-semibold text-white text-center mb-5'>If you would like to participate in this project, please submit below details:</h4>
                        <Form>
                            <FormGroup>
                                <Label className='text-white'>First Name</Label>
                                <Input
                                    type='text'
                                    name='first_name'
                                    className='text-white py-4'
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label className='text-white'>Last Name</Label>
                                <Input
                                    type='text'
                                    name='last_name'
                                    className='text-white py-4'
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label className='text-white'>Phone</Label>
                                <Input
                                    type='text'
                                    name='phone'
                                    className='text-white py-4'
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label className='text-white'>Email</Label>
                                <Input
                                    type='email'
                                    name='email'
                                    className='text-white py-4'
                                />
                            </FormGroup>
                            <div className='text-center mt-4'>
                                <Button type='submit' className="button-50 py-3 px-4 px-sm-5 ff-clash-bold mx-0">SUBMIT
                                </Button>
                            </div>
                        </Form>
                    </Col>
                    <Col xs="12" sm="2">
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Follow_Us