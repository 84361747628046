
import Footer from "components/Footer/Footer";
import IndexNavbar from "components/Navbars/IndexNavbar";
import React from "react";
// core components
import Banner from "./Banner";
import FAQ from "./FAQ";
import AboutUs from "./About_Us";
import FollowUs from "./Follow_Us";


export default function Index() {
  React.useEffect(() => {
    document.body.classList.toggle("index-page");
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("index-page");
    };

  }, []);
  return (
    <>
      <div className="wrapper" >
        <IndexNavbar />
        <Banner />
        <AboutUs />
        <FollowUs />
        <Footer />
      </div>
    </>
  );
}
