/*!

=========================================================
* Future-Cubes - v1.0.0
=========================================================
* Coded by Ecrubit
*/
import React from "react";
// reactstrap components
import {
  Row,
  Col,
  Container
} from "reactstrap";

import { AnchorScroll } from "helper";

export default function Footer() {
  return (
    <section className="pb-2">
      <Container>
        <div>
          <Row className="text-center text-md-left align-items-center">
            <Col md="4" lg="4" sm="12" xs="12" className="mt-3 mt-lg-0 d-flex flex-column">
              <div className='d-flex align-items-center justify-content-center justify-content-md-start mb-3'>
                <a href="/"><div className="rounded-lg p-5 d-flex flex-column align-items-center justify-content-center logo-box">
                  <h2 className="text-white mb-0 ff-logo">Cubes</h2>
                  <h2 className="text-white mb-0 ff-logo">NFTs</h2>
                </div></a>
              </div>
              <p className="ff-general-regular text-white">Lorem ipsum dolor sit amet consectetur. Diam mi sagittis arcu felis nunc risus aliquet. Augue massa fringilla quisque.</p>
              <div className="d-none d-md-flex mt-5">
                <div className="ff-general-regular text-white mr-md-4">2023 All rights reserved</div>
                {/* <p className="ff-general-regular text-white">Privacy Policy</p> */}
              </div>
            </Col>
            <Col md="3" lg="3" sm="12" xs="12" className="mt-4 mt-md-0 d-flex align-items-center justify-content-center">
              <div className="footer-text d-flex flex-column text-primary">
                <a href="/" rel='noreferrer'><h2 className=" ff-mona-semibold text-white">Home</h2></a>
                <a href="/#about" rel='noreferrer' className="mr-md-3"><h2 className="ff-mona-semibold text-white mb-0">About Us</h2></a>
                {/* <a href="/#faq" rel='noreferrer'><h2 className=" ff-mona-semibold text-white">FAQ's</h2></a> */}
              </div>
            </Col>
            <Col md="3" lg="3" sm="12" xs="12" className="mt-4 mt-md-0 d-flex align-items-center justify-content-center">
              <div className="d-flex flex-column align-items-center justify-content-center">
                <h2 className='ff-mona-semibold text-white'>Follow Us</h2>
                <a href="https://www.instagram.com/aiarteye" target={'_blank'} rel='noreferrer' ><img src={require('../../assets/img/Landing_Page/insta.png').default} alt="insta" className="img-fluid" width={80} /></a>
              </div>
            </Col>
            <Col md="2" lg="2" sm="12" xs="12" className="text-center text-md-right">
              <div className="d-md-none d-flex flex-column my-3">
                <div className="ff-general-regular text-white mb-4 mb-md-0">2023 All rights reserved</div>
                {/* <p className="ff-general-regular text-white">Privacy Policy</p> */}
              </div>
              <img src={require('../../assets/img/Landing_Page/back-to-top.png').default} alt="back-to-top" className="img-fluid cursor-pointer back-to-top" onClick={AnchorScroll} width={150} height={100} />
            </Col>
          </Row>
        </div>
      </Container >
    </section >
  );
}
