import React from 'react'
import Mint from '../../components/Mint'
export default function Banner() {

  return (
    <div className="px-md-2 position-rel text-center">
      <img src={require('../../assets/img/Landing_Page/banner-image.png').default} alt="Future-Cubes" className="img-fluid mt-5 w-75 banner-image" />
      <div className='bg-blur p-2'>
        <div className='text-right'>
          <img src={require('../../assets/img/Landing_Page/search.png').default} alt="Future-Cubes" className="img-fluid" width={40} />
        </div>
        <h4 className='ff-general-medium text-white text-center my-2 my-sm-4'>Blue Chip Blockchain presents CUBES Original and Collaborative Fine Art Pieces.</h4>

        <Mint />
      </div>
      <Mint />
    </div>
  )
}