import React from 'react'
import { Row, Col, Container } from 'reactstrap'

const About_Us = () => {
    return (
        <section className='px-0 nft-collection' id="about">
            <Container>
                <Row className='align-items-center'>
                    <Col md="5" className='text-center text-md-left'>
                        <h2 className='sub-header text-white'>About Us</h2>
                        <div className='d-flex align-items-center justify-content-center justify-content-md-start'>
                            <div className="rounded-lg p-5 d-flex flex-column align-items-center justify-content-center about-box">
                                <h2 className="text-white mb-0 ff-logo">Cubes</h2>
                                <h2 className="text-white mb-0 ff-logo">NFTs</h2>
                            </div>
                        </div>
                    </Col>
                    <Col md="7" className='pl-md-5 mt-4 mt-md-0'>
                        <p className='ff-general-regular text-white font-16 mb-3'>Blue Chip Blockchain presents CUBES Original and Collaborative Fine Art Pieces. This NFT portfolio represents an artistic collaboration. Beginning with original works of art from diverse mediums, Artists collaborate to create unique cooperative art pieces. Art works are submitted to various AI Art generators,  and similar derivative works are created. This debut nft drop includes NFT character art and paintings inspired by iconic artists like van Gogh, Picasso, and Dali, along with each artist's own aesthetic. This collection pays homage to various themes, from Chicano art and cubist influences to Dia de los Muertos, sugar skulls, and colorful abstract expressionism. Paintings in oil, tempura, watercolor, sharpie, colored pencil, art pen, and acrylic paint converge with Digital and AI art.</p>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default About_Us